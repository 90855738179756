<template>
  <div class="container_view">
    <div class="button_view">
      <van-button @click="showWindow(1)">加入购物车</van-button>
      <van-button @click="showWindow(2)">立即购买</van-button>
    </div>
    <div class="header" :style="opacityStyle">
      <img src="../../assets/detail_return_bg.png" @click="toHome" alt />
      <!-- <img src="../../assets/detail_share_bg.png" alt="" /> -->
    </div>
    <div class="header1" :style="opacityTwoStyle">
      <van-icon name="arrow-left" @click="toHome" class="icon_view" />
      <div>
        <van-tabs
          :before-change="beforeChange"
          @click="toTop"
          :active="active"
          line-width="20px"
          line-height="4px"
          color="#00B05A"
        >
          <van-tab title="商品" :activity="active"></van-tab>
          <van-tab title="详情"></van-tab>
        </van-tabs>
      </div>
      <!-- <img style="width:30px;height:30px;margin-left:100px"/> -->
    </div>
    <van-swipe
      :autoplay="3000"
      style="margin-top: 60px; width: 100%; background: white"
      indicator-color="#000000"
    >
      <van-swipe-item
        style="height: 323px"
        v-for="(image, index) in swipeImages(item.images)"
        :key="index"
      >
        <img style="height;323px;width:100%;max-width:100%;max-height:100%" v-lazy="imgurl + image" />
      </van-swipe-item>
    </van-swipe>
    <div class="goods_info_view">
      <span class="goods_name_view">{{ item.name }}</span>
      <span class="goods_price_view">{{ item.introduction }}</span>
      <div class="goods_bottom_view">
        <div class="price_view">
          <span class="price_fh_view">₱</span>
          <span class="price_money_view">{{ price }}</span>
          <span class="price_number_view" v-if="item.skuVos">/{{item.skuVos[0].specs[0].content}}</span>
        </div>
      </div>
    </div>
    <div class="service_view">
      <span class="span_service_view">服务</span>
      <img class="img_serve_view" src="../../assets/good_detail_serve_icon.png" alt />
      <span class="m_price_view">及时送达</span>
      <!-- <span class="m_price_view">满100元包邮</span> -->
      <img class="zp_img_view" src="../../assets/good_detail_serve_icon.png" alt />
      <span class="zp_span_view">正品保证</span>
    </div>

    <div class="guige_view" @click="showWindow(3)">
      <div>
        <span style="font-size: 14px; color: #666666; margin-left: 12px">规格</span>
        <span style="font-size: 14px; color: #b2b2b2; margin-left: 25px">请选择</span>
      </div>
      <van-icon name="arrow" style="margin-right: 10px" />
    </div>

    <div class="cs_view">
      <span
        style="
          font-size: 14px;
          color: #666666;
          margin-left: 12px;
          margin-top: 12px;
        "
      >参数</span>
    </div>
    <div class="goods_detail_view">
      <span>商品详情</span>
      <div v-html="item.description" class="details"></div>
    </div>
    <van-action-sheet
      style="max-width: 540px; margin-left: 50%; transform: translateX(-50%)"
      v-model="showGuge"
    >
      <div class="popup_window_view">
        <div class="good-info-view">
          <img :src="imgurl + item.image" alt />
          <div style="display: flex; flex-direction: column; margin-top: 16px">
            <div>
              <span style="font-size: 14px; color: #df3436">₱</span>
              <span style="font-size: 24px; color: #df3436">{{ price }}</span>
            </div>
            <span style="font-size: 14px; color: #999999; margin-top: 10px">库存{{ repertory }}件</span>
            <span style="font-size: 14px; color: #3f3f3f; margin-top: 18px">
              {{
              descsTitle
              }}
            </span>
          </div>
        </div>
        <div class="popup_gg_view" v-for="(item,index) in descsData" :key="index">
          <p class="is_qk_view">
            {{
            item.descs
            }}
          </p>
          <div>
            <div
              v-for="(res, i) in item.content"
              :key="i"
              :class="`${res.checked === 1 ? 'select_view' : 'select_no_view'} ${(item.descs == '是否宰杀' || item.descs == '是否切块') ? 'big' :''}`"
              @click="handelCheck(res,item.content,item.descs)"
            >{{ res.content }}</div>
          </div>
          <!-- @click="handelCheck(res)" -->

          <!-- <span class="is_qk_view">
            {{
              descsList[0] && descsList[0].descs
            }}
          </span>
          <div style="display: flex; flex-direction: row" v-for="(item, i) in descsList" :key="i">
            <div
              :class="item.checked === 1 ? 'select_view' : 'select_no_view'"
              @click="handelCheck(item)"
            >{{ item.content }}</div>
          </div>-->
        </div>

        <div class="pay_view">
          <span class="pay_jin_num">购买数量</span>
          <div
            style="
              display: flex;
              flex-direction: row;
              border: 1px solid #f2f2f2;
              margin-left: 24px;
            "
          >
            <div class="left_jian_view" @click="subtraction">
              <img src="../../assets/jian_bg.png" alt />
            </div>
            <input style="font-size: 15px" type="text" v-model="val" />
            <div class="right_jian_view" @click="addition">
              <img src="../../assets/add_bg.png" alt />
            </div>
          </div>
        </div>
        <div style="height:65px"></div>
        <div class="popup_bottom_view">
          <van-button
            @click="shoppimgcart"
            class="select_button_view"
            v-show="showGugeindex == 1 || showGugeindex == 3"
          >加入购物车</van-button>
          <van-button
            @click="pay"
            class="select_no_button_view"
            v-show="showGugeindex == 2 || showGugeindex == 3"
          >立即购买</van-button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getToken } from "@/utils/auth.js";

import {
  imgApp,
  getGoodsDetails,
  cartAdd,
  getBySPu
} from "@/service/api/index.js";
export default {
  data() {
    return {
      radio: "",
      descsList: [],
      val: 0,
      showGugeindex: 1,
      imgurl: "",
      active: 1,
      qYes: null,
      zYes: null,
      checked: null,
      opacityStyle: { opacity: 1 },
      opacityTwoStyle: { opacity: 0 },
      showGuge: false,
      isSelect: false,
      isSelectZs: false,
      isBtnSelect: false,
      item: {},
      price: 0,
      descsData: [],
      content: "",
      name: "",
      specid: "",
      firstshow: true,
      repertory: "",
      stockingTime: ""
    };
  },
  computed: {
    swipeImages() {
      return function(data) {
        let arr = [];
        if (data) {
          arr = data.split(",");
        }
        return arr;
      };
    },
    descsTitle: {
      get() {
        const list = this.descsList.filter(e => e.checked === 1);
        if (!list.length) {
          return "请选择参数";
        } else {
          return list[0].content;
        }
      }
    }
  },
  created() {
    this.imgurl = imgApp;
    this.getGoodsDetails(this.$route.query.id);
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    subtraction() {
      if (this.val === 0) {
        return;
      } else {
        this.val = this.val - 1;
      }
    },
    addition() {
      if (this.item.totalStock == 0 && this.item.stockingTime == 30) {
        Toast("商品已售罄");
        return;
      } else {
        this.val = this.val + 1;
      }
    },
    handelCheck(item, data, name) {
      if (name != "是否切块" && name != "是否宰杀") {
        this.name = name;
        this.content = item.content;
        this.specid = item.id;
        for (let res of this.item.quotes) {
          for (let resitem of res.specIds.split(",")) {
            if (resitem == item.id) {
              this.$set(this, "price", res.price);
              this.$set(
                this,
                "repertory",
                res.repertory <= 0 ? 0 : res.repertory
              );
            }
          }
        }
      }
      for (let el of data) {
        this.$set(el, "checked", 2);
      }
      this.$set(item, "checked", 1);
    },
    getGoodsDetails(id) {
      getGoodsDetails({ id: id })
        .then(res => {
          this.item = res.data;
          this.stockingTime = this.item.stockingTime;
          this.price = this.item.quotes[0].price;
          this.repertory =
            this.item.quotes[0].repertory <= 0
              ? 0
              : this.item.quotes[0].repertory;
        })
        .catch(err => {});
    },
    handleScroll() {
      const top = document.documentElement.scrollTop; //获取scroll偏移值
      if (top >= 0 && top <= 150) {
        //实际按需求取范围
        const opacity = top / 150; //对opacity作计算，透明度从起始到1随偏移值而改变
        this.opacityTwoStyle = { opacity }; //实时返回给opacityStyle
      } else {
        const opacity = top / 150; //对opacity作计算，透明度从起始到1随偏移值而改变
        this.opacityStyle = { opacity };
      }
    },
    showWindow(id) {
      const token = getToken("token");
      if (!token) {
        return this.$router.push("/login");
      }
      this.showGuge = true;
      this.showGugeindex = id;
      getBySPu({ spuId: this.$route.query.id }).then(res => {
        if (res.status === 200) {
          this.descsList = JSON.parse(JSON.stringify(res.data));
          if (this.firstshow) {
            for (let i in res.data) {
              if (this.descsData.length == 0) {
                if (
                  res.data[i].descs.indexOf("切块") != -1 ||
                  res.data[i].descs.indexOf("宰杀") != -1
                ) {
                  this.descsData.push({
                    descs: `是否${res.data[i].descs}`,
                    content: [
                      {
                        content: "是",
                        id: res.data[i].id,
                        checked: 1
                      },
                      {
                        content: "否",
                        id: res.data[i].id,
                        checked: ""
                      }
                    ]
                  });
                } else {
                  this.descsData.push({
                    descs: res.data[i].descs,
                    content: [
                      {
                        content: res.data[i].content,
                        id: res.data[i].id,
                        checked: ""
                      }
                    ]
                  });
                }
              } else {
                let index = 0; //定义索引
                let have = this.descsData.some(function(a, j) {
                  index = j; //满足条件时给索引赋值
                  // return res.data[i].descs.indexOf(a.descs) != -1; //判断规格名称是否相同
                  return res.data[i].descs == a.descs; //判断规格名称是否相同
                });
                if (have) {
                  this.descsData[index].content.push({
                    content: res.data[i].content,
                    id: res.data[i].id,
                    checked: ""
                  });
                } else {
                  if (
                    res.data[i].descs.indexOf("切块") != -1 ||
                    res.data[i].descs.indexOf("宰杀") != -1
                  ) {
                    this.descsData.push({
                      descs: `是否${res.data[i].descs}`,
                      content: [
                        {
                          content: "是",
                          id: res.data[i].id,
                          checked: 1
                        },
                        {
                          content: "否",
                          id: res.data[i].id,
                          checked: ""
                        }
                      ]
                    });
                  } else {
                    this.descsData.push({
                      descs: res.data[i].descs,
                      content: [
                        {
                          content: res.data[i].content,
                          id: res.data[i].id,
                          checked: ""
                        }
                      ]
                    });
                  }
                }
              }
            }
            this.firstshow = false;
          }
        }
      });
    },
    qkClick(e) {
      if (e === 1) {
        if (this.qYes !== 1 && this.qNo !== 2) {
          this.qYes = 1;
        } else if (this.qYes === 1 && this.qNo !== 2) {
          this.qYes = 0;
        } else if (this.qYes !== 1 && this.qNo === 2) {
          this.qYes = 1;
          this.qNo = 0;
        }
      } else {
        if (this.qYes !== 1 && this.qNo !== 2) {
          this.qNo = 2;
        } else if (this.qYes !== 1 && this.qNo === 2) {
          this.qNo = 0;
        } else if (this.qYes === 1 && this.qNo !== 2) {
          this.qYes = 0;
          this.qNo = 2;
        }
      }
    },
    zsClick(e) {
      if (e === 1) {
        if (this.zYes !== 1 && this.zNo !== 2) {
          this.zYes = 1;
        } else if (this.zYes === 1 && this.zNo !== 2) {
          this.zYes = 0;
        } else if (this.zYes !== 1 && this.zNo === 2) {
          this.zYes = 1;
          this.zNo = 0;
        }
      } else {
        if (this.zYes !== 1 && this.zNo !== 2) {
          this.zNo = 2;
        } else if (this.zYes !== 1 && this.zNo === 2) {
          this.zNo = 0;
        } else if (this.zYes === 1 && this.zNo !== 2) {
          this.zYes = 0;
          this.zNo = 2;
        }
      }
    },
    pay() {
      let specIds = [];
      for (let el of this.descsData) {
        if (el.descs != "是否切块" && el.descs != "是否宰杀") {
          for (let i of el.content) {
            if (i.checked == "") {
              Toast.fail("请选择" + el.descs);
              return;
            } else if (this.val === 0) {
              Toast("请选择购买数量");
              return;
            } else {
              specIds.push(this.specid);
              break;
            }
          }
        }
        if (el.descs == "是否切块") {
          for (let res of el.content) {
            if (res.checked == 1 && res.content == "是") {
              specIds.push(res.id);
            }
          }
        }
        if (el.descs == "是否宰杀") {
          for (let res of el.content) {
            if (res.checked == 1 && res.content == "是") {
              specIds.push(res.id);
            }
          }
        }
      }
      if (this.val > this.repertory && this.stockingTime >= 30) {
        Toast.fail("超过库存");
        return;
      }
      this.$router.push({
        path: "/confirmOrder",
        query: {
          id: this.$route.query.id,
          name: this.item.name,
          descs: this.descs,
          content: this.content,
          specIds: specIds.join(","),
          num: this.val,
          price: this.price,
          page: "goods"
        }
      });
    },
    shoppimgcart() {
      // 加入购物车
      let specIds = [];
      for (let el of this.descsData) {
        if (el.descs != "是否切块" && el.descs != "是否宰杀") {
          for (let i of el.content) {
            if (i.checked == "") {
              Toast.fail("请选择" + el.descs);
              return;
            } else if (this.val === 0) {
              Toast("请选择购买数量");
              return;
            } else {
              specIds.push(this.specid);
              break;
            }
          }
        }
        if (el.descs == "是否切块") {
          for (let res of el.content) {
            if (res.checked == 1 && res.content == "是") {
              specIds.push(res.id);
            }
          }
        }
        if (el.descs == "是否宰杀") {
          for (let res of el.content) {
            if (res.checked == 1 && res.content == "是") {
              specIds.push(res.id);
            }
          }
        }
      }
      if (this.val > this.repertory && this.stockingTime >= 30) {
        Toast.fail("超过库存");
        return;
      }
      const params = new URLSearchParams();
      params.append("quantity", this.val);
      params.append("spuId", this.item.id);
      params.append("specIds", specIds.join(","));
      cartAdd(params)
        .then(res => {
          if (res.status == 200) {
            this.showGuge = false;
            Toast.fail("购物车添加成功");
          } else {
            Toast.fail(res.message);
          }
        })
        .catch(err => {});
    },
    toHome() {
      this.$router.go(-1);
    },
    beforeChange(index) {
      if (index == 0) {
        return false;
      }
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="less">
@media screen and (min-width: 768px) {
  .van-overlay {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 1;
    height: 100%;
    max-width: 540px;
    width: 100%;
    margin: 0px auto;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
  }
  body {
    max-width: 540px;
    margin: 0px auto;
  }
  .container_view {
    max-width: 540px;
  }
}
.header {
  max-width: 540px;
  width: 100%;
  height: 45px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  background: white;
  img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.header1 {
  max-width: 540px;
  width: 100%;
  height: 45px;
  position: fixed;
  top: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: white;
  .icon_view {
    width: 30px;
    height: 30px;
    opacity: 0;
    position: absolute;
    left: 10px;
  }
  div {
    width: 130px;
    height: 100%;
  }
}
.goods_info_view {
  width: 100%;
  height: 100px;
  background: white;
  display: flex;
  flex-direction: column;
  .goods_name_view {
    font-size: 16px;
    color: #3f3f3f;
    margin-left: 10px;
    margin-top: 8px;
  }
  .goods_price_view {
    font-size: 14px;
    color: #999999;
    margin-top: 4px;
    margin-left: 10px;
  }
  .goods_bottom_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .price_view {
      margin-left: 10px;
      margin-top: 4px;
      .price_fh_view {
        font-size: 16px;
        color: #df3436;
      }
      .price_money_view {
        font-size: 26px;
        color: #df3436;
      }
      .price_number_view {
        font-size: 12px;
        color: #999999;
      }
    }
    .xl_view {
      font-size: 12px;
      color: #999999;
      margin-right: 10px;
    }
  }
}
.service_view {
  width: 100%;
  height: 46px;
  background: white;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .span_service_view {
    font-size: 14px;
    color: #666666;
    margin-left: 12px;
  }
  .img_serve_view {
    width: 16px;
    height: 16px;
    margin-left: 30px;
  }
  .m_price_view {
    font-size: 14px;
    color: #3f3f3f;
    margin-left: 6px;
  }
  .zp_img_view {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }
  .zp_span_view {
    font-size: 14px;
    color: #3f3f3f;
    margin-left: 6px;
  }
}
.guige_view {
  width: 100%;
  height: 46px;
  background: white;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cs_view {
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}
.goods_detail_view {
  width: 100%;
  background: white;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  span {
    font-size: 14px;
    color: #666666;
    margin-left: 12px;
    margin-top: 12px;
  }
}
.button_view {
  max-width: 539px;
  width: 100%;
  height: 57px;
  background: white;
  border: 1px solid #ebebeb;
  position: fixed;
  z-index: 2;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  button:first-of-type {
    width: 158px;
    height: 36px;
    border-radius: 18px;
    border: 1px solid #00b05a;
    font-size: 13px;
    color: #00b05a;
  }
  button:last-of-type {
    width: 158px;
    height: 36px;
    font-size: 13px;
    color: white;
    background: linear-gradient(90deg, #01b15b 0%, #49d0a2 100%);
    border-radius: 18px;
  }
}
.popup_window_view {
  width: 100%;
  height: 410px;
  background: white;
  .good-info-view {
    display: flex;
    flex-direction: row;
    img {
      width: 110px;
      height: 110px;
      background: #ccc;
      margin-top: 10px;
      margin-left: 12px;
      margin-right: 11px;
    }
  }
  .popup_gg_view {
    width: 100%;
    min-height: 50px;
    display: flex;
    // flex-wrap: wrap;
    // flex-direction: row;
    align-items: center;
    background: white;
    border-bottom: 1px solid #f2f2f2;
    .is_qk_view {
      width: 64px;
      font-size: 14px;
      color: #3f3f3f;
      margin-left: 12px;
    }
    div {
      display: flex;
      flex-wrap: wrap;
      //  flex-direction: row;
      padding: 10px 0;
      .select_view {
        background: rgba(0, 176, 90, 0.1);
        border-radius: 4px;
        border: 1px solid #00b05a;
        padding: 0 4px;
        height: 33px;
        border-radius: 4px;
        margin-left: 24px;
        margin-bottom: 5px;
        font-size: 13px;
        color: black;
        line-height: 33px;
        text-align: center;
      }
      .select_no_view {
        padding: 0 4px;
        height: 33px;
        border: 1px solid #f2f2f2;
        background: #f2f2f2;
        border-radius: 4px;
        margin-left: 24px;
        margin-bottom: 5px;
        font-size: 13px;
        color: black;
        line-height: 33px;
        text-align: center;
      }
      .big {
        width: 50px;
      }
    }
  }
  .pay_view {
    width: 100%;
    height: 63px;
    display: flex;
    background: white;
    border-bottom: 1px solid #f2f2f2;
    flex-direction: row;
    align-items: center;
    // padding-bottom: 65px;
    .pay_jin_num {
      width: 64px;
      font-size: 14px;
      color: #3f3f3f;
      margin-left: 12px;
    }
    div {
      .left_jian_view {
        width: 34px;
        height: 34px;
        background: white;
        display: flex;
        border: 1px solid #f2f2f2;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
          width: 15px;
          height: 15px;
        }
      }
      input {
        width: 64px;
        height: 34px;
        border: none;
        text-align: center;
      }
      .right_jian_view {
        width: 34px;
        height: 34px;
        background: white;
        display: flex;
        border: 1px solid #f2f2f2;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .popup_bottom_view {
    width: 100%;
    height: 57px;
    padding: 0 10px;
    box-sizing: border-box;
    background: white;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ebebeb;
    justify-content: center;
    bottom: 0;
    .select_button_view {
      width: 300px;
      height: 36px;
      background: #acacac;
      border-radius: 18px;
      font-size: 13px;
      color: #00b05a;
      background: white;
      border: 1px solid #00b05a;
    }
    .select_no_button_view {
      width: 300px;
      height: 36px;
      border-radius: 18px;
      font-size: 13px;
      color: white;
      background: linear-gradient(90deg, #01b15b 0%, #49d0a2 100%);
      border-radius: 18px;
    }
  }
}
.details {
  p {
    img {
      width: 100% !important;
    }
  }
}
</style>
